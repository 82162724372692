import { Router, Route, Private } from '@redwoodjs/router'

const Routes = () => {
  return (
    <Router>
      <Route path="/job-result" page={JobResultPage} name="jobResult" />
      <Private unauthenticated="home">
        <Route path="/applications/{applicationName}/jobs/{jobName}/results/{run:Int}" page={JobResultPage} name="applicationJobJobResult" />
        <Route path="/applications/{applicationName}/jobs/{jobName}/results" page={JobResultsPage} name="applicationJobJobResults" />
        <Route path="/applications/{applicationName}/jobs/{name}" page={JobPage} name="applicationJob" />
        <Route path="/applications/{applicationName}/jobs" page={JobsPage} name="applicationJobs" />
        <Route path="/applications/new" page={NewApplicationPage} name="newApplication" />
        <Route path="/applications" page={ApplicationsPage} name="applications" />
        <Route path="/jobs/{jobName}/edit" page={JobResultsPage} name="editJob" />
        <Route path="/jobs/new" page={NewJobPage} name="newJob" />
        <Route path="/jobs/{name}" page={JobPage} name="job" />
        <Route path="/jobs" page={JobsPage} name="jobs" />
        <Route path="/job-results" page={JobResultsPage} name="jobResults" />
      </Private>
      <Route path="/" page={HomePage} name="home" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
